.ka {
    width: 100%;
}

table.ka-table {
    font-family: "Inter Medium";
    font-weight: 500;
    border-collapse: separate !important;
    border-spacing: 0 0.255rem;
    margin-top: -0.25rem;
    table-layout: auto;
    white-space: nowrap;

    thead {
        border: none;

        tr {
            border: none;
            height: 3rem;

            th {
                font-family: "Inter Medium";
                font-size: 1rem;
                line-height: 1rem;
                font-weight: 500;
                color: var(--chakra-colors-contentT20);
                background-color: var(--chakra-colors-primaryT95);
                padding: 0.75rem !important;
                border-top: 1px solid var(--chakra-colors-primaryT80);
                border-bottom: 1px solid var(--chakra-colors-primaryT80);
                z-index: auto !important;

                &:first-of-type {
                    border-left: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-left-radius: 0.25rem !important;
                    border-bottom-left-radius: 0.25rem !important;
                }

                &:last-of-type {
                    border-right: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-right-radius: 0.25rem !important;
                    border-bottom-right-radius: 0.25rem !important;
                }

                span.ka-icon-sort {
                    display: inline-block;
                }
            }
        }
    }

    tbody {
        border: none;

        tr {
            border: none;
            height: 3rem;

            /*
            cursor: pointer;
            &:hover{
                background-color: #efefef;
            }
            */

            td {
                font-family: "Inter Regular";
                font-size: 0.875rem;
                line-height: 0.875rem;
                font-weight: 500;
                color: var(--chakra-colors-contentT40);
                padding: 0.75rem !important;
                border-top: 1px solid var(--chakra-colors-primaryT80);
                border-bottom: 1px solid var(--chakra-colors-primaryT80);

                &:first-of-type {
                    border-left: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-left-radius: 0.25rem !important;
                    border-bottom-left-radius: 0.25rem !important;
                }

                &:last-of-type {
                    border-right: 1px solid var(--chakra-colors-primaryT80) !important;
                    border-top-right-radius: 0.25rem !important;
                    border-bottom-right-radius: 0.25rem !important;
                }
            }
        }
    }
}

.ka-paging {
    background-color: var(--chakra-colors-primaryT95);
    border: 1px solid var(--chakra-colors-primaryT80) !important;
    border-top-left-radius: 0.25rem !important;
    padding: 0.75rem !important;
    .ka-paging-pages,
    .ka-paging-sizes {
        padding: unset;
        li {
            background-color: var(--chakra-colors-outlineButtonBg);
            padding: 0.5rem 0.8rem !important;
            border-radius: 0.1875rem;
            min-width: unset !important;
            font-size: 0.75rem;
            text-align: center;
            border: 1px solid var(--chakra-colors-outlineButtonBorder)!important;
            line-height: 1rem;
            color: var(--chakra-colors-outlineButtonText);
            margin: 0 0.1875rem;

            &.ka-paging-size-active,
            &.ka-paging-page-index-active {
                background-color: var(--chakra-colors-primaryButtonBg);
                border: none;
                color: var(--chakra-colors-primaryButtonText);
            }
        }
    }
}
